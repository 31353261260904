<template>
  <div class="w900">
    <h2 class="subBanner"><img src="@/assets/img/icon_gdia.svg">{{$t('front.common.faq')}}</h2>
    <div class="faqwrap">
      <ul class="tabs">
        <li :class="{'current': faqType == 'all'}" @click="onSelectType('all')">
          <span>{{$t('front.gameCategory.all')}}</span>
        </li>
        <li :class="{'current': faqType == 'casino'}" @click="onSelectType('casino')">
          <span>{{$t('front.gameCategory.livecasino')}}</span>
        </li>
        <li :class="{'current': faqType == 'slot'}" @click="onSelectType('slot')">
          <span>{{$t('front.gameCategory.slot')}}</span>
        </li>
        <li :class="{'current': faqType == 'cash'}" @click="onSelectType('cash')">
          <span>{{$t('front.gameCategory.cash')}}</span>
        </li>
        <li :class="{'current': faqType == 'betInfo'}" @click="onSelectType('betInfo')">
          <span>{{$t('front.gameCategory.betting')}}</span>
        </li>
        <li :class="{'current': faqType == 'etc'}" @click="onSelectType('etc')">
          <span>{{$t('front.gameCategory.etc')}}</span>
        </li>
      </ul>
    </div>
    <ul class="qnawrap">
      <template v-for="(item, index) in list">
        <li v-if="item && (item.category == faqType || faqType == 'all')" v-bind:key="index + '_' + item.title" :class="{ on : !item.isOpen}">
          <h4 class="qnahead" @click="item.isOpen = !item.isOpen">
            <div>
               <span class="nbicon rdbg" v-if="item.codeName">{{ item.codeName }}</span>
               {{item.regDate}} // {{ item.title }}
            </div>
            <a class="onoff"></a>
          </h4>
          <div class="qnacon" v-if="!item.isOpen">
            <div class="qnaconin">
              <p v-html="item.content">
              </p>
            </div>
          </div>
        </li>
      </template>

    </ul>

  </div>

</template>

<script>

export default {
  name: 'faqList',
  components: {},
  data () {
    return {
      list: null,
      boardType: 'favor',
      faqType: 'all'
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSelectType (value) {
      this.faqType = value
      this.loadList()
    },
    onSearch (value) {
      this.loadList()
    },
    async loadList () {
      const params = {
        boardType: this.boardType
      }

      if (this.faqType !== 'all') {
        params.faqType = this.faqType
      } else {
        if (params.faqType) {
          delete params.faqType
        }
      }

      console.log(params, this.categoryCodeByCode)

      const result = await this.getBoardList(params, this.pageInfo.page)
      this.list = result.list
      this.list.forEach(item => {
        item.content = item.content.replace(/\n/g, '<br>')
        item.isOpen = true
        item.codeName = item.category ? (this.categoryCodeByCode.favor[item.category] ? this.categoryCodeByCode.favor[item.category].codeName : '기타') : '기타'
      })
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'qnaRead', params: { id: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
